const Me = {
  id: 1,
  title: "Francisco Gabriel Abreu Cornelio",
  subtitle:
    "Full Stack Developer (Remote) | ReactJs | C# | JavaScript | Challenge-aholic",
  description:
    "I'm a Full Stack Software Developer with over 6+ years of hands-on experience in designing and implementing software solutions using ReactJS, C#, and JavaScript. " +
    "I thrive in collaborative team environments, where I can contribute to and learn from others, but I'm equally capable of delivering high-quality results independently. " +
    "My passion lies in creating innovative IT solutions that not only meet client needs but also push the boundaries of what's possible. " +
    "I'm deeply committed to continuous personal and professional growth, constantly seeking to enhance my skills by learning new programming languages, adopting the latest technologies, and mastering agile methodologies. " +
    "Every day, I strive to improve not just my technical abilities but also my leadership and communication skills, ensuring that I grow both as a developer and as a professional in the industry.",
};
export default Me;
